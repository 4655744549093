.footer {
	margin-top: auto;
	padding-top: rem(70px);
	padding-bottom: 2em;
	position: relative;
	background: $color-brand url("/assets/images/bg-tech-inverted.svg") no-repeat
		100% 50% / cover;
	color: #fff;
	overflow: hidden;
	text-align: left;

	&__top {
		margin-bottom: 50px;
	}

	&__cols {
		display: grid;
		grid-gap: 50px;
		gap: 50px;
		margin-bottom: 75px;
		position: relative;
		z-index: 3;

		@include min(1280px) {
			grid-template-columns: 1fr 1fr;
		}
	}

	&__contact {
		display: grid;
		grid-gap: 30px;
		gap: 30px;

		@include min(666px) {
			grid-template-columns: minmax(rem(260px), 320px) minmax(
					auto,
					rem(420px)
				);
		}
	}

	&__links {
		display: grid;
		grid-gap: 30px;
		gap: 30px;

		@include min(400px) {
			grid-template-columns: 1fr 1fr;
		}

		@include min(800px) {
			grid-template-columns: 1fr minmax(auto, rem(280px));
		}
	}

	&__heading {
		font-weight: bold;
		font-size: rem(22px);
		color: inherit;
	}

	&__services-links {
		.list {
			@include minmax(800px, 1279px) {
				column-count: 2;
				column-gap: 30px;

				.list__item {
					break-inside: avoid;
				}
			}
			@include min(1600px) {
				column-count: 2;
				column-gap: 30px;

				.list__item {
					break-inside: avoid;
				}
			}
		}
	}

	@include min(990px) {
		&::after {
			content: url("/assets/images/m.svg");
			position: absolute;
			right: rem(-50px);
			bottom: rem(-20px);
			transform: rotate(-45deg);
			transform-origin: center;
		}
	}

	&__login-link {
		position: absolute;
		bottom: 0.5rem;
		left: 0.5rem;
		font-size: 1rem;
		opacity: 0;

		@include link(&) {
			color: inherit;
			text-decoration: none;
		}

		@include link-over(&) {
			opacity: 1;
		}
	}
}

.contact {
	&__row {
		display: flex;

		@include min(1600px) {
			margin-left: -51px;
		}

		&.contact__address {
			margin-top: 0;
			@include min(1600px) {
				padding-left: 51px;
			}
		}
	}
	&__name {
		text-transform: uppercase;
		margin-top: 1.5em;
		margin-bottom: 0.25em;

		@include min(1600px) {
			&::before {
				content: "";
				min-width: rem(36px);
				flex-shrink: 0;
				color: $color-secondary;
				margin-right: 15px;
			}
		}
	}
	&__label {
		min-width: rem(36px);
		flex-shrink: 0;
		text-align: center;
		color: $color-secondary;
		margin-right: 15px;
	}
}
