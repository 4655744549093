.gallery {
	display: grid;
	grid-gap: 30px;
	gap: 30px;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

	&__item {
		position: relative;
	}

	&__item,
	&__figure,
	&__link {
		display: flex;
		flex-direction: column;
	}

	&__figure,
	&__link {
		flex-grow: 1;
	}

	&__figure {
		margin: 0;
	}

	&__link {
		//line-height: 0;
		display: block;
		flex-grow: 1;
	}

	&__image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center top;
		border-radius: 8px;
		max-height: 400px;
	}
}
