
.link-button {
	border-radius: 300px;
	padding: .85em 3em;
	font-size: rem(18px);
	margin: 0;
	background-color: $color-brand;
	color: $color-light;
	display: inline-block;
	text-align: center;
	transition: background-color .15s ease, color .15s ease, box-shadow .25s ease;
	position: relative;
	box-shadow: none;

	@include link(&) {
		text-decoration: none;
		color: $color-light;
	}

	@include link-over(&) {
		background-color: $color-secondary;
		color: $color-dark;
	}

	&--with-shadow {
		@include link(&) {
			box-shadow: 0 10px 30px rgba($color-brand, .7);
		}

		@include link-over(&) {
			box-shadow: 0 20px 40px rgba($color-brand, .7);
		}
	}
}
