@use "sass:math";

/*
* BASE DEPENDENCIES
*/

@import "abstracts/mixins", "abstracts/functions", "abstracts/vars",
	"abstracts/animations", "abstracts/templates";

/*
* COMMON BLOCKS
*/

@import "layout/footer";

/*
* DESIGN COMPONENTS
*/

@import //"components/search-form",
	//"components/search",
	"components/hamburgers/hamburgers",
	//"components/table",
	"components/button",
	"components/link-button", "components/swipeview", "components/contact-form",
	"components/pagination", "components/gallery";

.infinity-button {
	-webkit-appearance: none;
	appearance: none;
	background: transparent none;
	border: 1px $color-brand solid;
	color: $color-brand;
	transition: background 0.1s ease-out, color 0.1s ease-out;
	width: 50px;
	height: 50px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	margin: 0;
	box-shadow: none;
	cursor: pointer;

	&:hover,
	&:focus {
		background-color: $color-brand;
		color: white;
	}

	svg {
		fill: currentColor;
	}
}

.additional-links {
	margin: 0 0 rem(20px) 0;
	width: 100%;

	@include min(600px) {
		margin: 0 0 rem(30px);
	}

	@include min(1280px) {
		width: auto;
		margin: 1rem 0;
		//transition: margin 0.2s ease-out;

		/* .pinned-header & {
		} */
	}

	&__list {
		list-style-type: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
		justify-content: center;
	}
	&__item {
		margin: 5px;

		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	&__link {
		@include link(&) {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			color: $color-brand;
			text-decoration: none;
			text-transform: uppercase;
			font-size: rem(12px);
			padding: 12px;
			border: 1px $color-brand solid;
			font-weight: bold;
			transition: background-color 0.1s ease-out, color 0.1s ease-out;

			@include min(600px) {
				padding: 13px 19px;
			}
		}
		@include link-over(&) {
			background-color: $color-brand;
			color: white;
		}

		&.active {
			@include link-all(&) {
				background-color: $color-brand;
				color: white;
				pointer-events: none;
			}
		}
	}

	&__icon {
		transition: fill 0.1s ease-out;
		fill: currentColor;
		margin-right: 9px;
	}
}

.list {
	list-style-type: none;
	margin: 0;
	padding: 0;

	&__item {
		margin: 0 0 rem(14px);
	}

	&__link {
		@include link(&) {
			font-size: 1rem;
			color: inherit;
			text-decoration: none;
		}
		@include link-over(&) {
			text-decoration: underline;
		}
	}
}

.references {
	[data-view="home"] & {
		padding: clamp(rem(40px), 6vw, rem(80px)) 0
			clamp(rem(60px), 10vw, rem(110px));
	}

	&__content {
		position: relative;
	}

	&__wrapper {
		overflow: auto;

		&[data-infinity-initialised] {
			overflow: hidden;

			@include min(500px) {
				margin-left: 70px;
				margin-right: 70px;
			}

			@include min($max-width) {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}

	&__heading {
		text-align: center;
	}

	&__list {
		padding: 0;
		margin: 0;
		list-style-type: none;

		[data-view="page"] & {
			display: grid;
			grid-gap: 30px;
			gap: 30px;

			@include min(600px) {
				grid-template-columns: 1fr 1fr;
			}

			@include min(1000px) {
				grid-template-columns: 1fr 1fr 1fr 1fr;
			}
		}

		[data-view="home"] & {
			display: flex;
			margin: 0 -15px;
		}
	}
	&__item {
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		width: 100%;

		[data-view="home"] & {
			padding: 0 15px;

			@include min(700px) {
				width: 50%;
			}

			@include min(900px) {
				width: math.div(100%, 3);
			}
			@include min(1200px) {
				width: 25%;
			}
		}
	}

	&__image {
		margin: 0 0 1rem;
		aspect-ratio: 37 / 27;
		object-fit: cover;
		object-position: center;

		&.references__image--placeholder {
			display: block;
			background: $color-brand url("/assets/images/m.svg") no-repeat 50% 50% /
				80px auto;
		}
	}
	&__name {
		font-size: rem(20px);
		font-weight: normal;
		margin: 0;
	}
	&__description {
		font-size: rem(12px);
		font-weight: 800;
	}

	&__nav {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 20px;

		@include min(500px) {
			position: absolute;
			left: 4%;
			right: 4%;
			top: 50%;
			margin-top: 40px;
			transform: translateY(-100%);
		}

		@include min($max-width) {
			left: 0;
			right: 0;
		}
	}
}

.services {
	padding: clamp(35px, 4vw, 75px) 0 clamp(60px, 10vw, 174px);
	position: relative;
	background: {
		image: url("/assets/images/bg-tech.svg"), $gradient-secondary;
		repeat: no-repeat;
		position: 0 50%;
		blend-mode: color-burn, normal;
		size: cover, auto;
	}

	[data-view="home"] & {
		@include min(800px) {
			&::after {
				content: url("/assets/images/m.svg");
				position: absolute;
				right: rem(30px);
				bottom: rem(-60px);
				mix-blend-mode: multiply;
				transform: rotate(-45deg);
				transform-origin: center;
			}
		}
	}

	&__content {
		position: relative;
		z-index: 2;
	}

	&__list {
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: clamp(25px, 3vw, 90px);
		gap: clamp(25px, 3vw, 90px);

		@include min(400px) {
			grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
		}

		@include min(800px) {
			grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
		}
	}

	&__link {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		//word-break: break-all;

		@include link(&) {
			text-transform: uppercase;
			text-decoration: none;
			color: $color-brand;
			font-size: rem(13px);
			font-weight: 800;

			@include min(500px) {
				font-size: clamp(rem(18px), 3vw, rem(26px));
			}
		}
		@include link-over(&) {
			text-decoration: underline;
		}
	}

	svg {
		margin-bottom: 15px;

		@include min(600px) {
			margin-bottom: 30px;
		}

		path {
			fill: white;

			&.accent {
				fill: #1d2438;
			}
		}

		@include max(800px) {
			width: 120px;
			height: 120px;
		}
	}
}
