.contact-form {
	display: grid;
	grid-gap: 6px;
	gap: 6px;
	position: relative;
	color: white;

	&::after {
		content: "";
		width: 75px;
		height: 75px;
		background: transparent url(#{$imagePath}svg-loaders/form-loader.svg)
			no-repeat 0 0 / 100%;
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: 10;
		transform: translate(-50%, -50%);
		display: none;
	}

	&.is-loading {
		opacity: 0.5;
		pointer-events: none !important;

		&::after {
			display: block;
		}

		* {
			pointer-events: none !important;
		}
	}

	&__label {
		margin: 0 0 0.25em;
		padding: 0;
		display: inline-block;
	}

	&__submit-section {
		margin-top: 22px;
		text-align: left;
	}

	&__button {
		-webkit-appearance: none;
		appearance: none;
		border-radius: 0;
		background: transparent none;
		border: 1px $color-secondary solid;
		color: $color-secondary;
		font-size: 1rem;
		box-shadow: none;
		transition: background-color 0.1s ease-out, color 0.1s ease-out;

		&:hover,
		&:focus,
		&:active {
			background-color: $color-secondary;
			color: $color-dark;
		}
	}

	&__input,
	&__textarea {
		@extend %form-element-template;
		min-height: $form-item-standard-size;
		background-color: transparent;
		border: 1px white solid;
		border-radius: 0;
		color: white;

		&::placeholder {
			color: inherit;
			opacity: 1;
		}

		&:focus {
			border-color: $color-secondary;
		}

		&--has-error {
			border-color: rgb(161, 28, 28) !important;
		}
	}

	&__input {
		padding: 0.5em 20px;
	}

	&__textarea {
		resize: vertical;
		min-height: rem(108px);
		padding: 0.85em 20px;
	}

	&__fail-message,
	&__success-message,
	&__error-message {
		font-size: rem(14px);
		margin: 0.75em 0 0;
	}

	&__success-message {
		color: rgb(64, 160, 45);
	}

	&__fail-message,
	&__error-message {
		color: rgb(161, 28, 28);
	}

	.h-captcha {
		text-align: center;
	}
}
