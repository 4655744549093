/*
* BASE DEPENDENCIES
*/
/*****************************************
* Blackwell - MIXINS
*****************************************/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*
@keyframes fade-into-foreground {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(1.2);
		opacity: 0;
	}
}

@keyframes fade-into-background {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(.75);
		opacity: 0;
	}
}

@keyframes pop-into-foreground {
	from {
		transform: scale(.5);
		opacity: 0;
	} to {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pop-into-foreground-with-bounce {
	0% {
		transform: scale(.5);
		opacity: 0;
	} 72% {
		transform: scale(1.07);
		opacity: .72;
	} 100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
	} 100% {
		box-shadow: 0 0 0 1.5em rgba(0, 0, 0, 0);
	}
}

@keyframes slide-from-top {
	from {
		transform: translateY(-100%);
	} to {
		transform: translateY(0);
	}
}
*/
/*==============================
	INPUT, TEXTAREA, SELECT
==============================*/
.contact-form__input, .contact-form__textarea {
  transition: border-color 0.11s ease, box-shadow 0.21s ease;
  box-shadow: none;
  width: 100%;
  font-size: 1em;
  border-radius: 4px;
  border-color: hsl(0deg, 0%, 79%);
  border-style: solid;
  border-width: 1px;
  background-color: hsl(0deg, 0%, 100%);
  color: hsl(0deg, 0%, 9%);
  background-image: none;
  -webkit-appearance: none;
  appearance: none;
}
.contact-form__input:focus, .contact-form__textarea:focus {
  border-color: hsl(218deg, 76%, 67%);
  box-shadow: 0 8px 26px rgba(0, 0, 0, 0.12);
  outline: 0;
}
[disabled].contact-form__input, [disabled].contact-form__textarea {
  border-color: hsl(0deg, 0%, 89%);
  background-color: hsl(0deg, 0%, 95%);
  color: #303030;
  cursor: not-allowed;
}

/*==============================
	CHECKBOX, SWITCH, RADIO
==============================*/
/*
* COMMON BLOCKS
*/
.footer {
  margin-top: auto;
  padding-top: 4.375rem;
  padding-bottom: 2em;
  position: relative;
  background: #1d2337 url("/assets/images/bg-tech-inverted.svg") no-repeat 100% 50%/cover;
  color: #fff;
  overflow: hidden;
  text-align: left;
}
.footer__top {
  margin-bottom: 50px;
}
.footer__cols {
  display: grid;
  grid-gap: 50px;
  gap: 50px;
  margin-bottom: 75px;
  position: relative;
  z-index: 3;
}
@media screen and (min-width: 80rem) {
  .footer__cols {
    grid-template-columns: 1fr 1fr;
  }
}
.footer__contact {
  display: grid;
  grid-gap: 30px;
  gap: 30px;
}
@media screen and (min-width: 41.625rem) {
  .footer__contact {
    grid-template-columns: minmax(16.25rem, 320px) minmax(auto, 26.25rem);
  }
}
.footer__links {
  display: grid;
  grid-gap: 30px;
  gap: 30px;
}
@media screen and (min-width: 25rem) {
  .footer__links {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 50rem) {
  .footer__links {
    grid-template-columns: 1fr minmax(auto, 17.5rem);
  }
}
.footer__heading {
  font-weight: bold;
  font-size: 1.375rem;
  color: inherit;
}
@media screen and (min-width: 50rem) and (max-width: 79.9375rem) {
  .footer__services-links .list {
    column-count: 2;
    column-gap: 30px;
  }
  .footer__services-links .list .list__item {
    break-inside: avoid;
  }
}
@media screen and (min-width: 100rem) {
  .footer__services-links .list {
    column-count: 2;
    column-gap: 30px;
  }
  .footer__services-links .list .list__item {
    break-inside: avoid;
  }
}
@media screen and (min-width: 61.875rem) {
  .footer::after {
    content: url("/assets/images/m.svg");
    position: absolute;
    right: -3.125rem;
    bottom: -1.25rem;
    transform: rotate(-45deg);
    transform-origin: center;
  }
}
.footer__login-link {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  font-size: 1rem;
  opacity: 0;
}
.footer__login-link, .footer__login-link:link, .footer__login-link:visited {
  color: inherit;
  text-decoration: none;
}
.footer__login-link:hover, .footer__login-link:focus, .footer__login-link:active {
  opacity: 1;
}

.contact__row {
  display: flex;
}
@media screen and (min-width: 100rem) {
  .contact__row {
    margin-left: -51px;
  }
}
.contact__row.contact__address {
  margin-top: 0;
}
@media screen and (min-width: 100rem) {
  .contact__row.contact__address {
    padding-left: 51px;
  }
}
.contact__name {
  text-transform: uppercase;
  margin-top: 1.5em;
  margin-bottom: 0.25em;
}
@media screen and (min-width: 100rem) {
  .contact__name::before {
    content: "";
    min-width: 2.25rem;
    flex-shrink: 0;
    color: #84c450;
    margin-right: 15px;
  }
}
.contact__label {
  min-width: 2.25rem;
  flex-shrink: 0;
  text-align: center;
  color: #84c450;
  margin-right: 15px;
}

/*
* DESIGN COMPONENTS
*/
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
/* MARKUP */
/*
<button class="hamburger" type="button">
  <span class="hamburger-box">
    <span class="hamburger-inner"></span>
  </span>
</button>
 */
.hamburger {
  padding: 0 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #1d2337;
}

.hamburger-box {
  width: 34px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 34px;
  height: 4px;
  background-color: #1d2337;
  border-radius: 0;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -10px;
}
.hamburger-inner::after {
  bottom: -10px;
}

/*
 * Collapse
 */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.button {
  border-radius: 50px;
  padding: 0.85em 3em;
  font-size: 1.125rem;
  margin: 0;
  background-color: #1d2337;
  color: #fff;
  text-align: center;
  transition: background-color 0.15s ease, box-shadow 0.15s ease;
  position: relative;
  box-shadow: none;
  /* &[disabled] {
  	color: #999999;
  	background-color: #ebebeb;
  	cursor: not-allowed;
  }

  &.-icon {
  	&::before {
  		vertical-align: middle;
  		margin-right: .75em;
  		display: inline-block;
  	}
  }

  &.-primary {
  	background-color: $button-primary-bg;
  	box-shadow: 0 0 0 0 $button-primary-bg;
  	color: $button-primary-fg;

  	&:not([disabled]) {
  		&:hover,
  		&:focus,
  		&:active {
  			box-shadow: 0 5px 20px $button-primary-bg;
  		}
  	}
  } */
}
.button:not([disabled]):hover, .button:not([disabled]):focus, .button:not([disabled]):active {
  z-index: 3;
  outline: 0;
}
.button:nth-last-child(n+2), .button:nth-last-child(n+2) ~ .button {
  margin-bottom: 0.5em;
}

.button:not(:first-child):not(.-block) {
  margin-left: 0.5em;
}

.link-button {
  border-radius: 300px;
  padding: 0.85em 3em;
  font-size: 1.125rem;
  margin: 0;
  background-color: #1d2337;
  color: hsl(26deg, 28%, 95%);
  display: inline-block;
  text-align: center;
  transition: background-color 0.15s ease, color 0.15s ease, box-shadow 0.25s ease;
  position: relative;
  box-shadow: none;
}
.link-button, .link-button:link, .link-button:visited {
  text-decoration: none;
  color: hsl(26deg, 28%, 95%);
}
.link-button:hover, .link-button:focus, .link-button:active {
  background-color: #84c450;
  color: hsl(0deg, 0%, 3%);
}
.link-button--with-shadow, .link-button--with-shadow:link, .link-button--with-shadow:visited {
  box-shadow: 0 10px 30px rgba(29, 35, 55, 0.7);
}
.link-button--with-shadow:hover, .link-button--with-shadow:focus, .link-button--with-shadow:active {
  box-shadow: 0 20px 40px rgba(29, 35, 55, 0.7);
}

@keyframes swipeview-reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes swipeview-reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.swipeview {
  margin: 0;
  padding: 0;
  z-index: 30999;
  scroll-behavior: auto;
  display: block;
  opacity: 0;
  border: 0;
  background: transparent none;
}
.swipeview::backdrop {
  background: rgba(8, 8, 8, 0.85);
  backdrop-filter: blur(10px);
}
.swipeview:not([open]) {
  visibility: hidden;
}
.swipeview[open] {
  animation: swipeview-reveal 0.4s ease-out forwards 0.1s;
}
.swipeview__list-wrapper {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.swipeview__list {
  touch-action: manipulation;
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  height: calc(100vh + 40px);
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}
.swipeview__item {
  box-sizing: border-box;
  border: 10px transparent solid;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  background: transparent url(/assets/images/swipeview/loading.svg) no-repeat center center/100px auto !important;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}
.swipeview__image {
  user-select: none;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}
.swipeview__counter-wrapper {
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  padding: 10px 22px;
  border-radius: 500px;
  background-color: rgba(8, 8, 8, 0.65);
  color: hsl(26deg, 28%, 95%);
  font-size: 1rem;
}
.swipeview__button {
  position: fixed;
  box-shadow: none;
  outline: 0;
  z-index: 2;
  width: 50px;
  height: 50px;
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  margin: 0;
  border-radius: 500px;
  transition: background-color 0.18s ease-out;
  background-color: rgba(8, 8, 8, 0.65);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: none;
  background-size: 26px;
}
.swipeview__button:not([disabled]) {
  cursor: pointer;
}
.swipeview__button:not([disabled]):hover, .swipeview__button:not([disabled]):focus {
  outline: 0;
  background-color: #1d2337;
}
@media screen and (min-width: 50rem) {
  .swipeview__button {
    width: 70px;
    height: 70px;
  }
}
.swipeview__button[disabled] {
  opacity: 0.3;
}
.swipeview__button--exit {
  right: 20px;
  top: 20px;
  border-radius: 500px;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' width='40' height='40'%3E%3Cpath fill='hsl(26deg, 28%, 95%)' d='M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z'/%3E%3C/svg%3E");
}
.swipeview__button--previous, .swipeview__button--next {
  transform: translateY(-50%);
}
.swipeview__button--previous {
  left: 20px;
  top: 50%;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' width='40' height='40'%3E%3Cpath fill='hsl(26deg, 28%, 95%)' d='M8.502 20c-.003-.259.092-.519.289-.716l19.008-19.007c.389-.389 1.02-.389 1.408 0s.389 1.019 0 1.407l-18.315 18.316 18.315 18.315c.389.389.389 1.019 0 1.407s-1.02.389-1.408 0l-19.008-19.006c-.197-.197-.292-.457-.289-.716z'/%3E%3C/svg%3E");
}
.swipeview__button--next {
  right: 20px;
  top: 50%;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' width='40' height='40'%3E%3Cpath fill='hsl(26deg, 28%, 95%)' d='M31.209 20.716l-19.008 19.007c-.389.389-1.019.389-1.407 0s-.389-1.019 0-1.407l18.314-18.316-18.315-18.315c-.389-.389-.389-1.019 0-1.407s1.019-.389 1.407 0l19.008 19.007c.197.197.292.457.289.716.004.258-.091.518-.288.715z'/%3E%3C/svg%3E");
}

.contact-form {
  display: grid;
  grid-gap: 6px;
  gap: 6px;
  position: relative;
  color: white;
}
.contact-form::after {
  content: "";
  width: 75px;
  height: 75px;
  background: transparent url(/assets/images/svg-loaders/form-loader.svg) no-repeat 0 0/100%;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  display: none;
}
.contact-form.is-loading {
  opacity: 0.5;
  pointer-events: none !important;
}
.contact-form.is-loading::after {
  display: block;
}
.contact-form.is-loading * {
  pointer-events: none !important;
}
.contact-form__label {
  margin: 0 0 0.25em;
  padding: 0;
  display: inline-block;
}
.contact-form__submit-section {
  margin-top: 22px;
  text-align: left;
}
.contact-form__button {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  background: transparent none;
  border: 1px #84c450 solid;
  color: #84c450;
  font-size: 1rem;
  box-shadow: none;
  transition: background-color 0.1s ease-out, color 0.1s ease-out;
}
.contact-form__button:hover, .contact-form__button:focus, .contact-form__button:active {
  background-color: #84c450;
  color: hsl(0deg, 0%, 3%);
}
.contact-form__input, .contact-form__textarea {
  min-height: 46px;
  background-color: transparent;
  border: 1px white solid;
  border-radius: 0;
  color: white;
}
.contact-form__input::placeholder, .contact-form__textarea::placeholder {
  color: inherit;
  opacity: 1;
}
.contact-form__input:focus, .contact-form__textarea:focus {
  border-color: #84c450;
}
.contact-form__input--has-error, .contact-form__textarea--has-error {
  border-color: rgb(161, 28, 28) !important;
}
.contact-form__input {
  padding: 0.5em 20px;
}
.contact-form__textarea {
  resize: vertical;
  min-height: 6.75rem;
  padding: 0.85em 20px;
}
.contact-form__fail-message, .contact-form__success-message, .contact-form__error-message {
  font-size: 0.875rem;
  margin: 0.75em 0 0;
}
.contact-form__success-message {
  color: rgb(64, 160, 45);
}
.contact-form__fail-message, .contact-form__error-message {
  color: rgb(161, 28, 28);
}
.contact-form .h-captcha {
  text-align: center;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: clamp(2.5rem, 4vw, 5rem) auto 0;
}
.pagination__list {
  padding: 0;
  margin: 1em 0;
  list-style-type: none;
  text-align: center;
  align-items: center;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  color: #1d2337;
}
.pagination__item {
  margin: 0 7px;
  display: inline-flex;
  align-items: center;
}
.pagination__item span {
  display: inline-flex;
  align-items: center;
}
.pagination__item--previous {
  margin-right: 28px;
}
.pagination__item--next {
  margin-left: 28px;
}
.pagination__item [aria-disabled] {
  opacity: 0.5;
  filter: grayscale(1);
}
.pagination__item [aria-current=page] {
  font-weight: 800;
}
.pagination__link {
  color: inherit;
  display: inline-flex;
  align-items: center;
}
.pagination__link, .pagination__link:link, .pagination__link:visited {
  text-decoration: underline;
}
.pagination__link:hover, .pagination__link:focus, .pagination__link:active {
  text-decoration: none;
}

.gallery {
  display: grid;
  grid-gap: 30px;
  gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
.gallery__item {
  position: relative;
}
.gallery__item, .gallery__figure, .gallery__link {
  display: flex;
  flex-direction: column;
}
.gallery__figure, .gallery__link {
  flex-grow: 1;
}
.gallery__figure {
  margin: 0;
}
.gallery__link {
  display: block;
  flex-grow: 1;
}
.gallery__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
  border-radius: 8px;
  max-height: 400px;
}

.infinity-button {
  -webkit-appearance: none;
  appearance: none;
  background: transparent none;
  border: 1px #1d2337 solid;
  color: #1d2337;
  transition: background 0.1s ease-out, color 0.1s ease-out;
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  box-shadow: none;
  cursor: pointer;
}
.infinity-button:hover, .infinity-button:focus {
  background-color: #1d2337;
  color: white;
}
.infinity-button svg {
  fill: currentColor;
}

.additional-links {
  margin: 0 0 1.25rem 0;
  width: 100%;
}
@media screen and (min-width: 37.5rem) {
  .additional-links {
    margin: 0 0 1.875rem;
  }
}
@media screen and (min-width: 80rem) {
  .additional-links {
    width: auto;
    margin: 1rem 0;
    /* .pinned-header & {
    } */
  }
}
.additional-links__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
}
.additional-links__item {
  margin: 5px;
}
.additional-links__item:first-child {
  margin-left: 0;
}
.additional-links__item:last-child {
  margin-right: 0;
}
.additional-links__link, .additional-links__link:link, .additional-links__link:visited {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #1d2337;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.75rem;
  padding: 12px;
  border: 1px #1d2337 solid;
  font-weight: bold;
  transition: background-color 0.1s ease-out, color 0.1s ease-out;
}
@media screen and (min-width: 37.5rem) {
  .additional-links__link, .additional-links__link:link, .additional-links__link:visited {
    padding: 13px 19px;
  }
}
.additional-links__link:hover, .additional-links__link:focus, .additional-links__link:active {
  background-color: #1d2337;
  color: white;
}
.additional-links__link.active, .additional-links__link.active:link, .additional-links__link.active:visited, .additional-links__link.active:hover, .additional-links__link.active:focus, .additional-links__link.active:active {
  background-color: #1d2337;
  color: white;
  pointer-events: none;
}
.additional-links__icon {
  transition: fill 0.1s ease-out;
  fill: currentColor;
  margin-right: 9px;
}

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.list__item {
  margin: 0 0 0.875rem;
}
.list__link, .list__link:link, .list__link:visited {
  font-size: 1rem;
  color: inherit;
  text-decoration: none;
}
.list__link:hover, .list__link:focus, .list__link:active {
  text-decoration: underline;
}

[data-view=home] .references {
  padding: clamp(2.5rem, 6vw, 5rem) 0 clamp(3.75rem, 10vw, 6.875rem);
}
.references__content {
  position: relative;
}
.references__wrapper {
  overflow: auto;
}
.references__wrapper[data-infinity-initialised] {
  overflow: hidden;
}
@media screen and (min-width: 31.25rem) {
  .references__wrapper[data-infinity-initialised] {
    margin-left: 70px;
    margin-right: 70px;
  }
}
@media screen and (min-width: 99.125rem) {
  .references__wrapper[data-infinity-initialised] {
    margin-left: 0;
    margin-right: 0;
  }
}
.references__heading {
  text-align: center;
}
.references__list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
[data-view=page] .references__list {
  display: grid;
  grid-gap: 30px;
  gap: 30px;
}
@media screen and (min-width: 37.5rem) {
  [data-view=page] .references__list {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 62.5rem) {
  [data-view=page] .references__list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
[data-view=home] .references__list {
  display: flex;
  margin: 0 -15px;
}
.references__item {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}
[data-view=home] .references__item {
  padding: 0 15px;
}
@media screen and (min-width: 43.75rem) {
  [data-view=home] .references__item {
    width: 50%;
  }
}
@media screen and (min-width: 56.25rem) {
  [data-view=home] .references__item {
    width: 33.3333333333%;
  }
}
@media screen and (min-width: 75rem) {
  [data-view=home] .references__item {
    width: 25%;
  }
}
.references__image {
  margin: 0 0 1rem;
  aspect-ratio: 37/27;
  object-fit: cover;
  object-position: center;
}
.references__image.references__image--placeholder {
  display: block;
  background: #1d2337 url("/assets/images/m.svg") no-repeat 50% 50%/80px auto;
}
.references__name {
  font-size: 1.25rem;
  font-weight: normal;
  margin: 0;
}
.references__description {
  font-size: 0.75rem;
  font-weight: 800;
}
.references__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
@media screen and (min-width: 31.25rem) {
  .references__nav {
    position: absolute;
    left: 4%;
    right: 4%;
    top: 50%;
    margin-top: 40px;
    transform: translateY(-100%);
  }
}
@media screen and (min-width: 99.125rem) {
  .references__nav {
    left: 0;
    right: 0;
  }
}

.services {
  padding: clamp(35px, 4vw, 75px) 0 clamp(60px, 10vw, 174px);
  position: relative;
  background-image: url("/assets/images/bg-tech.svg"), linear-gradient(to right, #84c450, #1b5962);
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-blend-mode: color-burn, normal;
  background-size: cover, auto;
}
@media screen and (min-width: 50rem) {
  [data-view=home] .services::after {
    content: url("/assets/images/m.svg");
    position: absolute;
    right: 1.875rem;
    bottom: -3.75rem;
    mix-blend-mode: multiply;
    transform: rotate(-45deg);
    transform-origin: center;
  }
}
.services__content {
  position: relative;
  z-index: 2;
}
.services__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: clamp(25px, 3vw, 90px);
  gap: clamp(25px, 3vw, 90px);
}
@media screen and (min-width: 25rem) {
  .services__list {
    grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
  }
}
@media screen and (min-width: 50rem) {
  .services__list {
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  }
}
.services__link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.services__link, .services__link:link, .services__link:visited {
  text-transform: uppercase;
  text-decoration: none;
  color: #1d2337;
  font-size: 0.8125rem;
  font-weight: 800;
}
@media screen and (min-width: 31.25rem) {
  .services__link, .services__link:link, .services__link:visited {
    font-size: clamp(1.125rem, 3vw, 1.625rem);
  }
}
.services__link:hover, .services__link:focus, .services__link:active {
  text-decoration: underline;
}
.services svg {
  margin-bottom: 15px;
}
@media screen and (min-width: 37.5rem) {
  .services svg {
    margin-bottom: 30px;
  }
}
.services svg path {
  fill: white;
}
.services svg path.accent {
  fill: #1d2438;
}
@media screen and (max-width: 50rem) {
  .services svg {
    width: 120px;
    height: 120px;
  }
}