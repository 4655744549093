.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: clamp(rem(40px), 4vw, rem(80px)) auto 0;

	&__list {
		padding: 0;
		margin: 1em 0;
		list-style-type: none;
		text-align: center;
		align-items: center;
		display: flex;
		align-items: center;
		font-size: rem(12px);
		color: $color-brand;
	}

	&__item {
		margin: 0 7px;
		display: inline-flex;
		align-items: center;

		span {
			display: inline-flex;
			align-items: center;
		}

		&--previous {
			margin-right: 28px;
		}

		&--next {
			margin-left: 28px;
		}

		[aria-disabled] {
			opacity: 0.5;
			filter: grayscale(1);
		}

		[aria-current="page"] {
			font-weight: 800;
		}
	}

	&__link {
		color: inherit;
		display: inline-flex;
		align-items: center;

		@include link(&) {
			text-decoration: underline;
		}
		@include link-over(&) {
			text-decoration: none;
		}
	}
}
